import React, { useEffect, useState } from 'react';
import { emptyGUID } from '../../../core/utilities/String';
import { parseQueryString } from '../../../core/utilities/URL';
import MSEmbeddedPublicReport from '../../../report-microstrategy/components/templates/public/MSEmbeddedPublicReport';
import MSEmbeddedPageReport from '../../../report-microstrategy/components/templates/report-only/MSEmbeddedPageReport';
import { IReportDropdownData } from '../../../reporting/entities/ReportDropdownData';
import { defaultReportPage, IReportPage } from '../../../reporting/entities/ReportPage';
import { useGetReportHeader } from '../../../reporting/services/ReportFilterService';
import { useGetReportPublicPagePageByOrganization } from '../../../reporting/services/ReportPageService';
import { IUser } from '../../../user/entities/User';
import { defaultHeaderText, defaultHeaderTextEmtpy, IHeaderText } from '../../entities/HeaderText';

import { useLocation, useNavigate } from 'react-router';
import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { IRoute } from '../../entities/Route';
import Header from '../components/Header';
import { getSiteSetting, SiteSetting } from '../../entities/SiteSettings';

interface IComponentInfo {
    toggleCollapse: () => void;
    menuCollapsed: boolean;
    siteMenu: MenuEntity[];
    isLoadingMenu: boolean;
    user: IUser;
    routes: IRoute[];
}

const ReportingLayout: React.FunctionComponent<IComponentInfo> = ({ siteMenu, toggleCollapse, menuCollapsed, user, routes }) => {
    const params: any = parseQueryString();
    const { service: pageService, setItem } = useGetReportPublicPagePageByOrganization();
    const { service: headerService, setKeyword } = useGetReportHeader();

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageId] = useState(params['pid'] || '');
    const [organization] = useState(params['organization'] || '');
    const [headerText, setHeaderText] = useState<IHeaderText>(defaultHeaderText)

    const [headerWidth, setHeaderWidth] = useState<number>(parseInt(getSiteSetting(SiteSetting.ReportContentWidth)));

    const { pathname } = useLocation();
    const [pageName, setPageName] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        const page = routes.filter(rt => rt.path === pathname);
        if (pathname === '/home' || pathname === '/') {
            setHeaderText({
                ...defaultHeaderTextEmtpy,
                middle: 'Welcome to the Kentucky School Report Card',
                left: 'Report Card',
                right: '2023-24'
            });
        } else {
            setHeaderText({
                ...defaultHeaderTextEmtpy,
                left: 'Report Card',
                right: '2023-24',
                middle: 'Kentucky School Report Card'
            });
        }
        setPageName(page[0]?.name ?? "")
    }, [navigate, pageName, pathname, routes])

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        setKeyword(organization);
        // eslint-disable-next-line
    }, [organization]);

    useEffect(() => {
        if (headerService.result) {
            const c: IReportDropdownData[] = headerService.result;
            const school: string = c.filter(q => q.typeId === 'S')[0].name;
            const district: string = c.filter(q => q.typeId === 'D')[0].name;
            setHeaderText({
                ...headerText,
                middle: school === 'All Schools' ? (district === 'All Districts' ? 'Kentucky' : district) : school,
                left: 'Report Card',
                right: '2023-24'
            });
        }
        // eslint-disable-next-line
    }, [headerService]);

    useEffect(() => {
        if (siteMenu.length > 0) {
            if (isSingleReport()) {
                setHeaderText({
                    ...defaultHeaderTextEmtpy,
                    middle: 'Kentucky School Report Card',
                    left: 'Report Card',
                    right: '2023-24'
                });
            } else {
                setItem({ organization, id: pageId });
            }
        }
        // eslint-disable-next-line
    }, [siteMenu]);

    useEffect(() => {
        setItem({ organization, id: pageId });
        // eslint-disable-next-line
    }, [pageId]);

    const isSingleReport = (): boolean => {
        switch (window.location.pathname) {
            case '/embedded-report-public':
                return true;
            default:
                return false;
        }
    };
    return (
        <>
            <Header
                collapsed={menuCollapsed}
                toggleCollapse={toggleCollapse}
                headerText={headerText}
                headerWidth={headerWidth}
                layout={1}
            />
            <div className='layout-definition'>
                <div className='body-container' style={{ width: '100%' }}>
                    {!isSingleReport() && !emptyGUID(page.id) && (
                        <MSEmbeddedPublicReport
                            pageId={page.id}
                            organization={organization}
                            user={user}
                            headerText={headerText}
                            setHeaderWidth={setHeaderWidth}
                            pageName={pageName}
                        />
                    )}
                    {isSingleReport() && (
                        <MSEmbeddedPageReport isPublic={emptyGUID(user.id)} />
                    )}
                </div>
            </div>
        </>
    );
};

export default ReportingLayout;