import React, { Children, cloneElement } from 'react';
import { getSiteSetting, SiteSetting } from '../../entities/SiteSettings';

import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';


interface IComponentInfo {
    children?: any;
    collapsed: boolean;
    siteMenu: MenuEntity[];
    pathname: string;
    pageName: string;
}

const pagesWithoutScroll = ['/glossary', '/schools', '/districts'];

const Body: React.FunctionComponent<IComponentInfo> = ({ children, collapsed, siteMenu, pathname = '', pageName }) => {


    return (
        <div className='body-container'
            style={{
                width: pathname === "/home" || pathname === '/' ? '100%' : 'calc(100% - 290px)',
                overflow: pagesWithoutScroll.includes(pathname) ? 'unset' : 'overlay',
                padding: pathname === "/home" || pathname === '/' ? 0 : 40
            }}>
            {Children.map(children, (child) => {
                return cloneElement(child, {
                    children: Children.map(child.props.children, (child_2) => {
                        return cloneElement(child_2, {
                            element: React.isValidElement(child_2.props.element) ? cloneElement(child_2.props.element, { siteMenu, selectedYear: getSiteSetting(SiteSetting.AcademicYear) }) : child_2.props.element
                        })
                    })
                })
            })
            }
        </div >
    );
};


export default Body;
